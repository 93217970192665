.log-list-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.log-conatiner {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.log-header {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.log-author {
    font-weight: 500;
}

.log-timestamp {
    font-size: 14px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.4);
}

.log-content {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.log-content-text {
    display: flex;
    flex-grow: 1;
}

.status-change {
    color: rgba(0, 0, 0, 0.4);
    border-left: 3px solid #1890ff;
    padding-left: 8px;
}

.log-attachments {
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
}

.log-attachment {
    width: 80px;
}