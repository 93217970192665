.apartment-availability-calendar-cell {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.apartment-availability-calendar-day-box {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.apartment-availability-calendar-month-box {
    width: 90px;
    height: 30px;
    border-radius: 5px;
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.apartment-availability-calendar-box-selected {
    border: 1px solid #1890ff;
}

.apartment-availability-calendar-box-available {
    background-color: #95de64;
}

.apartment-availability-calendar-box-unavailable {
    background-color: #ff7875;
}

.apartment-availability-calendar-box-undefined {
    background-color: #ffc069;
}