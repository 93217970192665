.job-author-link {
    color: rgba(160, 160, 160, 0.85);
    cursor: pointer;
}

.job-author-link:hover {
    color: #40a9ff;
}

.job-author-link:active {
    color: #096dd9;
}

.job-author-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.job-author-container-row {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.job-author-container-label {
    width: 100px;
    color: #a0a0a0;
}