.nodes-panel-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 200px;
    max-height: 400px;
    overflow-y: scroll;
    padding: 8px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}

.nodes-panel-category-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.nodes-panel-category-header {
    font-weight: 600;
    text-transform: uppercase;
}