.login-screen-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(240, 242, 245);
}

.login-box {
    width: 300px;
}

.support-container {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 16px;
}