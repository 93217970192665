.user-availability-status-container {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.user-availability-status-circle {
    border-radius: 100%;
}

.user-availability-status-square {
    border-radius: 4px;
}

.user-availability-status-comment-badge {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.8);
}