.cleaning-status-changes-container {
    display: flex;
    flex-direction: column;
}

.cleaning-status-changes-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding: 8px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.cleaning-status-changes-item:last-child {
    border-bottom: none;
}

.cleaning-status-changes-item-description {
    color: rgba(0, 0, 0, 0.8);
}

.cleaning-status-changes-item-timestamp {
    color: rgba(0, 0, 0, 0.5);
}

.cleaning-status-changes-item-timestamp-editable:hover {
    color: #1890ff;
    cursor: pointer;
}