.price-config-weekday-container {
    display: flex;
    flex-direction: row;
}

.price-config-weekday {
    width: 14.28%;
    height: 32px;
    text-align: center;
    color: #888888;
    cursor: pointer;
    user-select: none;
}

.price-config-week {
    width: 100%;
    height: 72px;
    font-size: 12px;
    line-height: 72px;
    text-align: right;
    padding-right: 8px;
    border-top: 1px solid #E0E0E0;
    cursor: pointer;
    user-select: none;
}

.price-config-week-selected {
    background-color: #e6f7ff;
    color: #096dd9;
}

.price-config-day-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.price-config-day {
    width: 14.28%;
    height: 72px;
    display: flex;
    flex-direction: row;
    position: relative;
    border-top: 1px solid #E0E0E0;
    border-left: 1px solid #F4F4F4;
    cursor: pointer;
    user-select: none;
}

.price-config-day-selected {
    background-color: #e6f7ff;
    color: #096dd9;
}

.price-config-day-date {
    position: absolute;
    top: 4px;
    left: 4px;
    font-size: 12px;
    color: #404040;
}

.price-config-day-delta {
    position: absolute;
    width: 45px;
    top: 10px;
    right: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    line-height: 14px;
}

.price-config-day-min-price {
    position: absolute;
    width: 45px;
    top: 30px;
    right: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    line-height: 14px;
}

.price-config-day-slope {
    position: absolute;
    width: 45px;
    top: 50px;
    right: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    line-height: 14px;
}

.price-config-day-price {
    position: absolute;
    top: 24px;
    right: 70px;
    font-size: 16px;
    text-align: right;
    color: #808080;
}

.price-config-day-param-label {
    color: #A0A0A0;
    font-size: 10px;
    line-height: 10px;
}

.price-config-day-param-value {
    font-size: 14px;
    line-height: 14px;
}

.price-config-day-param-changed {
    font-weight: bold;
}