.recent-jobs-list-container {
    display: flex;
    flex-direction: column;
}

.recent-jobs-list-job-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 4px 8px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.recent-jobs-list-job-container:hover {
    background-color: rgb(250, 250, 250);
    border-color: rgb(230, 230, 230);
}

.recent-jobs-list-job-loading {
    border-color: #69c0ff !important;
}

.recent-jobs-list-job-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 8px;
    align-items: flex-start;
}

.recent-jobs-list-job-container-first-col {
    width: 300px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 4px;
}

.recent-jobs-list-job-title {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.recent-jobs-list-job-container-second-col {
    width: 200px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 4px;
}

.recent-jobs-list-job-container-third-col {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
    gap: 4px;
}

.recent-jobs-list-job-container-fourth-col {
    min-width: 0;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 4px;
}

.recent-jobs-list-job-priority {
    padding-top: 4px;
}

.recent-jobs-list-job-author {
    align-self: flex-end;
}