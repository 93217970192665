.messages-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.message-container {
    display: flex;
}

.message-guest {
    flex-direction: row;
}

.message-host {
    flex-direction: row-reverse;
}

.message-content {
    width: 70%;
    padding: 8px;
    border-radius: 8px;
    background-color: #f8f8f8;
    color: #404040;
    line-height: 1.5;
    white-space: pre-line;
}

.message-template {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    padding: 8px;
    border-radius: 8px;
    background-color: #f8f8f8;
    color: #404040;
}

.message-template-label {
    color: #a0a0a0;
}

.attachment {
    width: 70%;
}

.attachment img {
    width: 100%;
    border-radius: 8px;
}

.mark {
    background-color: #1890ff;
    color: #ffffff;
}

.message-timestamp {
    width: 30%;
    padding-top: 8px;
    color: #a0a0a0;
}

.message-guest .message-timestamp {
    text-align: right;
}

.message-host .message-timestamp {
    text-align: left;
}