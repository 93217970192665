.cleaning-day-statistics-badge-container {
    width: 30px;
    height: 30px;
    background-color: #b7eb8f;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
}

.cleaning-day-statistics-badge-loading {
    background-color: #f0f0f0;
}

.cleaning-day-statistics-badge-error {
    background-color: #ffa39e;
}