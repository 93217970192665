.availability-calendar-container {
    flex: 1;
    min-height: 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.availability-calendar-controls-container {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
}

.availability-calendar-content-container {
    flex: 1;
    min-height: 0;
}

.availability-calendar-row-header-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 8px;
    user-select: none;
    cursor: pointer;
}

.availability-calendar-column-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    user-select: none;
    cursor: pointer;
}

.availability-calendar-column-header-day-month {
    font-size: 12px;
    text-align: center;
}

.availability-calendar-column-header-weekday {
    font-size: 12px;
    text-align: center;
}

.availability-calendar-column-header-weekday-weekend {
    font-weight: 800;
}

.availability-calendar-cell-container {
    width: 100%;
    height: 100%;
    padding: 4px;
}

.availability-calendar-cell-disabled {
    background-color: #f0f0f0;
}