.cleaning-timeline-list-container {
    display: flex;
    flex-direction: column;
}

.cleaning-group-timeline-container {
    display: flex;
    flex-direction: row;
    padding: 8px 0;
    gap: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.cleaning-group-timeline-container:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.cleaning-group-timeline-container:last-child {
    border-bottom: none;
}

.cleaning-group-cleaner-name {
    width: 150px;
    padding-left: 8px;
}

.cleaning-group-timeline-timeline {
    flex: 1;
    position: relative;
}

.cleaning-group-timeline-timeline-dot {
    width: 5px;
    height: 20px;
    border-radius: 10px;
    background-color: #389e0d;
    position: absolute;
    top: 1px;
}

.cleaning-group-timeline-timeline-block {
    height: 20px;
    border-radius: 5px;
    background-color: #40a9ff;
    border: 1px solid #096dd9;
    position: absolute;
    top: 1px;
}

.cleaning-group-timeline-timeline-block-not-finished {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.cleaning-group-timeline-status {
    width: 100px;
}