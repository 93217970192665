.job-reservation-situation-inline-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.job-reservation-situation-inline-badge {
    width: 10px;
    height: 10px;
    border-radius: 5px;
}

.job-reservation-situation-inline-badge-green {
    background-color: #52c41a;
}

.job-reservation-situation-inline-badge-red {
    background-color: #f5222d;
}