.cleaning-list-container {
    display: flex;
    flex-direction: column;
}

.cleaning-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 28px;
    padding: 0px 4px;
    gap: 8px;
    cursor: pointer;
}

.cleaning-list-item:hover {
    background-color: rgb(250, 250, 250);
}

.cleaning-list-item-selected {
    background-color: #e6f7ff;
}

.cleaning-list-status {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: rgb(230, 230, 230);
}

.cleaning-list-status-in-progress {
    background-color: #69c0ff;
}

.cleaning-list-status-finished {
    background-color: #95de64;
}

.cleaning-list-name {
    width: 200px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.cleaning-list-order {
    width: 50px;
}

.cleaning-list-cleaners {
    width: 200px;
}