.calendar-day-cell-container {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
}

.calendar-day-cell-unavailable {
    background-color: rgb(240, 240, 240);
}

.calendar-day-cell-today {
    background-color: #e6f7ff;
}

.calendar-day-cell-selected {
    background-color: #91d5ff;
}

.calendar-starting-reservation-container {
    position: absolute;
    top: 5px;
    right: -1px;
    background-color: #91d5ff;
    height: 20px;
    cursor: pointer;
    z-index: 10;
    width: 40px;
    border-radius: 4px 0 0 4px;
    border-top: 1px solid #40a9ff;
    border-left: 1px solid #40a9ff;
    border-bottom: 1px solid #40a9ff;
}

.calendar-ending-reservation-container {
    position: absolute;
    top: 5px;
    left: -1px;
    background-color: #91d5ff;
    height: 20px;
    cursor: pointer;
    z-index: 10;
    width: 5px;
    border-radius: 0 4px 4px 0;
    border-top: 1px solid #40a9ff;
    border-right: 1px solid #40a9ff;
    border-bottom: 1px solid #40a9ff;
}

.calendar-ongoing-reservation-container {
    position: absolute;
    top: 5px;
    background-color: #91d5ff;
    height: 20px;
    cursor: pointer;
    z-index: 10;
    width: 51px;
    border-top: 1px solid #40a9ff;
    border-bottom: 1px solid #40a9ff;
}

.calendar-value-container {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 20px;
    line-height: 20px;
    font-size: 11px;
    z-index: 20;
    color: rgb(100, 100, 100);
}

.calendar-reservation-value-container {
    position: absolute;
    top: 5px;
    right: 0;
    height: 20px;
    line-height: 20px;
    font-size: 11px;
    z-index: 20;
    color: rgb(0, 0, 0);
    text-align: right;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
}

.calendar-reservation-ota-airbnb {
    border-color: #ff5a60;
    background-color: #ff999d;
}

.calendar-reservation-ota-booking {
    border-color: rgb(31, 92, 179);
    background-color: rgb(127, 171, 234);
}