.handle-input1 {
    top: 16px;
}

.handle-input2 {
    top: 40px;
}

.handle-input3 {
    top: 64px;
}

.handle-input4 {
    top: 88px;
}

.handle-input5 {
    top: 112px;
}

.handle-output1 {
    top: 16px;
}

.handle-output2 {
    top: 40px;
}

.handle-output3 {
    top: 64px;
}

.handle-output4 {
    top: 88px;
}

.handle-output5 {
    top: 112px;
}

.node-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    min-width: 50px;
}

.node-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    height: 24px;
    padding: 0 12px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    border-bottom: 1px solid #e0e0e0;
    background-color: #f8f8f8;
    border-radius: 4px 4px 0 0;
}

.node-labels {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    padding: 0 12px;
}

.node-input-labels {
    display: flex;
    flex-direction: column;
}

.node-input-label {
    height: 24px;
}

.node-output-labels {
    display: flex;
    flex-direction: column;
}

.node-output-label {
    height: 24px;
    text-align: right;
}

.handle-label {
    position: absolute;
    top: -8px;
    left: 8px;
    white-space: nowrap;
    font-size: 14px;
}

.node-handle {
    width: 12px;
    height: 12px;
    background-color: #ffffff;
    border: 1px solid #808080;
}