.user-availability-day-details-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 32px;
}

.user-availability-day-details-header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
}

.user-availability-day-details-header-date {
    font-size: 18px;
}

.user-availability-day-details-header-user-name {
    color: rgb(150, 150, 150);
}

.user-availability-day-details-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.user-availability-day-details-changes-history {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.user-availability-day-details-change {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.user-availability-day-details-change-changed-at {
    width: 100%;
    color: rgb(100, 100, 100);
}

.user-availability-day-details-change-comment {
    width: 100%;
}

.user-availability-day-details-change-updater {
    width: 100%;
}