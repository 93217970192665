.cleaning-information-cleaner-card-container {
    display: flex;
    flex-direction: column;
}

.cleaning-information-cleaner-card-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 28px;
    padding: 0px 4px;
    gap: 8px;
    cursor: pointer;
}

.cleaning-information-cleaner-card-item:hover {
    background-color: rgb(250, 250, 250);
}

.cleaning-information-cleaner-card-status {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: rgb(230, 230, 230);
}

.cleaning-information-cleaner-card-status-in-progress {
    background-color: #69c0ff;
}

.cleaning-information-cleaner-card-status-finished {
    background-color: #95de64;
}

.cleaning-information-cleaner-card-apartment-name {
    flex: 1;
}

.cleaning-information-apartment-status-info {
    flex: 1;
    color: rgb(150, 150, 150);
    text-align: right;
}