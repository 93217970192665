.job-assigners-list-nowrap {
    overflow: hidden;
    white-space: nowrap;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.job-assigners-list-link {
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
}

.job-assigners-list-link:hover {
    color: #40a9ff;
}

.job-assigners-list-link:active {
    color: #096dd9;
}

.job-assigners-list-empty {
    color: #a0a0a0;
}