.calendar-day-details-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 32px;
}

.calendar-day-details-header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
}

.calendar-day-details-header-date {
    font-size: 18px;
}

.calendar-day-details-header-apartment-name {
    color: rgb(150, 150, 150);
}

.calendar-day-details-section {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.calendar-day-details-section-name {
    font-weight: 600;
}

.calendar-day-details-reservations-history {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.calendar-day-details-reservation {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.calendar-day-details-reservation-label {
    color: rgb(180, 180, 180);
}

.calendar-day-details-calendar-history {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.calendar-day-details-calendar-change {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.calendar-day-details-calendar-change-available {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 2px solid rgb(150, 150, 150);
}

.calendar-day-details-calendar-change-unavailable {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 2px solid rgb(150, 150, 150);
    background-color: rgb(150, 150, 150);
}

.calendar-day-details-calendar-change-right {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.calendar-day-details-calendar-change-changed-at {
    width: 100%;
    color: rgb(100, 100, 100);
}

.calendar-day-details-calendar-change-values {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.calendar-day-details-calendar-change-label {
    color: rgb(180, 180, 180);
}

.calendar-day-details-calendar-change-price {
    width: 50%;
}

.calendar-day-details-calendar-change-min-stay {
    width: 50%;
}

.calendar-day-details-calendar-change-comment {
    width: 100%;
}

.calendar-day-details-calendar-change-updater {
    width: 100%;
}

.calendar-day-details-price-config-history {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.calendar-day-details-price-config-change {
    display: flex;
    flex-direction: column;
}

.calendar-day-details-price-config-change-changed-at {
    width: 100%;
    color: rgb(100, 100, 100);
}

.calendar-day-details-price-config-change-values {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.calendar-day-details-price-config-change-label {
    color: rgb(180, 180, 180);
}

.calendar-day-details-price-config-change-min-price {
    width: 33.3%;
}

.calendar-day-details-price-config-change-delta {
    width: 33.3%;
}

.calendar-day-details-price-config-change-slope {
    width: 33.3%;
}