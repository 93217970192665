.job-status-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.job-status-badge {
    width: 10px;
    height: 10px;
    border-radius: 5px;
}

.job-status-link {
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
}

.job-status-link:hover {
    color: #40a9ff;
}

.job-status-link:active {
    color: #096dd9;
}