.apartments-for-cleaning-container {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}

.apartments-for-cleaning-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    border-bottom: 1px solid rgb(230, 230, 230);
}

.apartments-for-cleaning-title {
    font-size: 14px;
    font-weight: 600;
}

.apartments-for-cleaning-info {
    color: rgb(150, 150, 150);
}

.apartments-for-cleaning-content {
    display: flex;
    flex-direction: row;
}

.apartments-for-cleaning-quick-actions-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 8px;
}

.empty-selection-info {
    color: rgb(150, 150, 150);
}

.apartments-for-cleaning-list-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}