.thumbnail-box {
    width: 100%;
    aspect-ratio: 1/1;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.thumbnail-box img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    user-select: none;
}

.thumbnail-box video {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    user-select: none;
}

.thumbnail-box .thumbnail-icon {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    color: white;
    font-size: 40px;
    opacity: 0.8;
    pointer-events: none;
}