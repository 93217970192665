.cleaning-group-container {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    position: relative;
}

.cleaning-group-move-here-container {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #1890ff;
    cursor: pointer;
}

.cleaning-group-container:hover .cleaning-group-move-here-container {
    display: block;
}

.cleaning-group-move-here-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.cleaning-group-status {
    width: 8px;
    align-self: stretch;
    border-left: 4px solid rgb(230, 230, 230);
}

.cleaning-group-status-submitted {
    border-left: 4px solid #1890ff;
}

.cleaning-group-status-confirmed {
    border-left: 4px solid #52c41a;
}

.cleaning-group-container-left {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 290px;
    padding: 2px 8px;
}

.cleaning-group-container-right {
    width: 100%;
}

.cleaning-group-cleaners-label {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.cleaning-group-cleaners-label.empty {
    color: rgb(150, 150, 150);
}

.cleaning-group-cleaners-label:hover {
    color: #1890ff;
    cursor: pointer;
}

.cleaning-group-cleaner-container-loading {
    width: 100px;
    height: 22px;
    border-radius: 4px;
    background-color: rgb(230, 230, 230);
}

.cleaning-group-cleaner-container-error {
    width: 100px;
    height: 22px;
    border-radius: 4px;
    background-color: #ffa39e;
}

.cleaning-group-cleaner-container {
    display: inline-flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}

.cleaning-group-cleaner-availability {
    width: 12px;
    height: 12px;
    border-radius: 4px;
}

.cleaning-group-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.cleaning-group-info-header {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
}

.cleaning-group-info-item-label {
    color: rgb(200, 200, 200);
    margin-right: 4px;
}

.cleaning-group-info-item-value:hover {
    color: #1890ff;
    cursor: pointer;
}

.cleaning-group-apartments-empty-warning {
    padding: 2px 8px;
    margin-left: 28px;
    color: #f5222d;
}