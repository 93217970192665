.cleaning-information-apartment-card-container {
    display: flex;
    flex-direction: column;
}

.cleaning-information-apartment-card-item {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.cleaning-information-apartment-card-label {
    width: 100px;
    color: rgb(150, 150, 150);
    text-align: right;
    flex-shrink: 0;
}

.cleaning-information-apartment-card-unknown {
    color: rgb(150, 150, 150);
}

.cleaning-information-apartment-card-title-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.cleaning-information-apartment-card-subtitle {
    font-size: 12px;
    color: rgb(150, 150, 150);
}

.cleaning-information-apartment-jobs-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 16px;
}

.cleaning-information-apartment-jobs-title {
    font-size: 18px;
}

.cleaning-information-apartment-job {
    display: flex;
    flex-direction: row;
    gap: 16px;
}