.cleaners-select-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.cleaners-select-relation-container {
    display: flex;
    flex-direction: column;
}

.cleaners-select-relation-label {
    color: rgb(150, 150, 150);
    font-size: 12px;
}

.cleaners-select-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 4px;
    cursor: pointer;
}

.cleaners-select-item-container:hover {
    background-color: rgb(245, 245, 245);
}

.cleaners-select-item-selected,
.cleaners-select-item-selected:hover {
    background-color: #e6f7ff;
}

.cleaners-select-item-disabled,
.cleaners-select-item-disabled:hover {
    background-color: rgb(240, 240, 240);
    cursor: not-allowed;
}

.cleaners-select-item-availability {
    width: 20px;
    height: 20px;
    border-radius: 4px;
}

.cleaners-select-item-name {
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.cleaners-select-item-comment {
    color: rgb(150, 150, 150);
    overflow: hidden;
}