.calendar-date-cell-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.calendar-date-cell-weekend {
    font-weight: bold;
}

.calendar-date-cell-today {
    background-color: #1890ff;
    color: #ffffff;
}