.cleaning-apartment-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 0 8px;
    width: 100%;
    align-items: center;
}

.cleaning-apartment-container-small {
    height: 25px;
}

.cleaning-apartment-container-medium {
    height: 35px;
}

.cleaning-apartment-container-large {
    height: 45px;
}

.cleaning-apartment-container:hover {
    background-color: rgb(245, 245, 245);
}

.cleaning-apartment-container.cleaning-apartment-dragging:hover {
    background-color: none;
}

.cleaning-apartment-selected,
.cleaning-apartment-selected:hover {
    background-color: #e6f7ff;
}

.cleaning-apartment-disabled {
    background-color: #f8f8f8;
    color: rgba(0, 0, 0, 0.3);
}

.cleaning-apartment-head {
    width: 20px;
}

.cleaning-apartment-handle {
    visibility: hidden;
    cursor: grab;
}

.cleaning-apartment-container:hover .cleaning-apartment-handle {
    visibility: visible;
}

.cleaning-apartment-handle:active {
    cursor: grabbing;
}

.cleaning-apartment-loading {
    height: 20px;
    background-color: #f0f0f0;
    border-radius: 4px;
}

.cleaning-apartment-apartment-name {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 1);
    width: 200px;
    text-wrap: nowrap;
}

.cleaning-apartment-disabled .cleaning-apartment-apartment-name {
    color: rgba(0, 0, 0, 0.5);
}

.cleaning-apartment-warning {
    color: #fa8c16;
}

.cleaning-apartment-apartment-name .cleaning-apartment-loading {
    width: 150px;
}

.cleaning-apartment-storage-name {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    width: 120px;
}

.cleaning-apartment-disabled .cleaning-apartment-storage-name {
    color: rgba(0, 0, 0, 0.2);
}

.cleaning-apartment-storage-name .cleaning-apartment-loading {
    width: 80px;
}

.cleaning-apartment-reservations {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    justify-content: center;
    overflow: hidden;
}

.cleaning-apartment-ongoing-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 320px;
}

.cleaning-apartment-ongoing-label:hover {
    color: #1890ff;
    cursor: pointer;
}

.cleaning-apartment-disabled .cleaning-apartment-ongoing-label:hover {
    cursor: default;
}

.cleaning-apartment-departure-container {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    flex-direction: row;
    gap: 16px;
    justify-content: flex-end;
    overflow: hidden;
}

.cleaning-apartment-departure-container .cleaning-apartment-loading {
    width: 100px;
}

.cleaning-apartment-arrival-container {
    display: flex;
    flex-basis: 0;
    flex-grow: 1;
    flex-direction: row;
    gap: 16px;
    overflow: hidden;
}

.cleaning-apartment-arrival-container .cleaning-apartment-loading {
    width: 100px;
}

.cleaning-apartment-no-reservation {
    color: rgba(0, 0, 0, 0.5);
}

.cleaning-apartment-disabled .cleaning-apartment-no-reservation {
    color: rgba(0, 0, 0, 0.2);
}

.cleaning-apartment-no-departure {
    width: 60px;
    text-align: right;
    color: rgba(0, 0, 0, 0.8);
}

.cleaning-apartment-disabled .cleaning-apartment-no-departure {
    color: rgba(0, 0, 0, 0.4);
}

.cleaning-apartment-no-departure:hover {
    color: #1890ff;
    cursor: pointer;
}

.cleaning-apartment-disabled .cleaning-apartment-no-departure:hover {
    cursor: default;
    color: rgba(0, 0, 0, 0.4);
}

.cleaning-apartment-no-arrival {
    width: 60px;
    text-align: left;
    color: rgba(0, 0, 0, 0.8);
}

.cleaning-apartment-disabled .cleaning-apartment-no-arrival {
    color: rgba(0, 0, 0, 0.4);
}

.cleaning-apartment-no-arrival:hover {
    cursor: pointer;
    color: #1890ff;
}

.cleaning-apartment-disabled .cleaning-apartment-no-arrival:hover {
    cursor: default;
    color: rgba(0, 0, 0, 0.4);
}

.cleaning-apartment-number-of-guests {
    display: flex;
    width: 30px;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}

.cleaning-apartment-ongoing-reservation {
    display: flex;
    flex-direction: row;
    width: 160px;
    justify-content: center;
    align-items: center;
}

.cleaning-apartment-no-ongoing-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 320px;
}

.cleaning-apartment-divider {}

.cleaning-apartment-guests {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.cleaning-apartment-guests-icon {
    color: rgb(150, 150, 150);
}

.cleaning-apartment-disabled .cleaning-apartment-guests-icon {
    color: rgb(200, 200, 200);
}

.cleaning-apartment-guests-container {
    display: flex;
    justify-content: center;
    width: 80px;
}

.cleaning-apartment-guests-ongoing {
    display: flex;
    justify-content: flex-end;
    width: 20px;
}

.cleaning-apartment-guests-departing {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 20px;
}

.cleaning-apartment-guests-arriving {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 20px;
}

.cleaning-apartment-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.cleaning-apartment-time-container {
    display: flex;
    justify-content: center;
    width: 160px;
}

.cleaning-apartment-checkout-time {
    display: flex;
    justify-content: flex-end;
    width: 60px;
}

.cleaning-apartment-checkin-time {
    display: flex;
    justify-content: flex-start;
    width: 60px;
}

.cleaning-apartment-departure-container .cleaning-apartment-number-of-guests {
    justify-content: flex-end;
}

.cleaning-apartment-arrival-container .cleaning-apartment-number-of-guests {
    justify-content: flex-start;
}

.cleaning-apartment-number-of-guests .icon {
    color: rgba(0, 0, 0, 0.5);
}

.cleaning-apartment-disabled .cleaning-apartment-number-of-guests .icon {
    color: rgba(0, 0, 0, 0.2);
}

.cleaning-apartment-departure-container .cleaning-apartment-time {
    width: 60px;
    text-align: right;
}

.cleaning-apartment-arrival-container .cleaning-apartment-time {
    width: 60px;
    text-align: left;
}

.cleaning-apartment-no-time {
    color: rgba(0, 0, 0, 0.5);
}

.cleaning-apartment-disabled .cleaning-apartment-no-time {
    color: rgba(0, 0, 0, 0.2);
}

.cleaning-apartment-jobs {
    width: 55px;
    text-align: right;
    cursor: pointer;
}

.cleaning-apartment-jobs-link {
    color: rgb(200, 200, 200);
}

.cleaning-apartment-disabled .cleaning-apartment-jobs-link {
    color: rgb(230, 230, 230);
}

.cleaning-apartment-jobs-link:hover {
    color: #1890ff;
}

.cleaning-apartment-disabled .cleaning-apartment-jobs-link:hover {
    color: rgb(230, 230, 230);
}

.cleaning-apartment-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 20px;
    color: rgb(150, 150, 150);
}

.cleaning-apartment-disabled .cleaning-apartment-actions {
    color: rgb(200, 200, 200);
}

.cleaning-apartment-empty {
    color: rgba(0, 0, 0, 0.5);
}

.cleaning-apartment-divider {
    width: 30px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
}

.reservation-information-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 250px;
    height: 82px;
}

.reservation-information-row {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.reservation-information-label {
    width: 60px;
    color: rgb(200, 200, 200);
}

.no-departure-information-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.no-arrival-information-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.no-arrival-information-container-loading {
    width: 240px;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-departure-information-container-loading {
    width: 240px;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.microcalendar-container {
    display: flex;
    flex-direction: row;
    gap: -1px;
}

.microcalendar-item {
    width: 40px;
    height: 40px;
    position: relative;
}

.microcalendar-item-date {
    position: absolute;
    width: 40px;
    font-size: 10px;
    text-align: center;
    color: rgb(150, 150, 150);
}

.microcalendar-item-box {
    position: absolute;
    left: 0;
    top: 15px;
    width: 40px;
    height: 25px;
    border-top: 1px solid rgb(200, 200, 200);
    border-right: 1px solid rgb(200, 200, 200);
    border-bottom: 1px solid rgb(200, 200, 200);
}

.microcalendar-item-first .microcalendar-item-box {
    border-left: 1px solid rgb(200, 200, 200);
}

.microcalendar-item-available {
    background-color: #ffffff;
}

.microcalendar-item-unavailable {
    background-color: rgb(230, 230, 230);
}

.microcalendar-item-min-stay-unavailable {
    position: absolute;
    z-index: 100;
    top: 18px;
    left: 17px;
    width: 35px;
    height: 19px;
    background-color: rgb(80, 80, 80);
    border-radius: 4px;
}

.microcalendar-item-reservation {
    position: absolute;
    top: 18px;
    height: 19px;
    background-color: #91d5ff;
    border: 1px solid #69c0ff;
}

.microcalendar-item-starting-reservation {
    left: 17px;
    width: 23px;
    border-radius: 4px 0 0 4px;
    border-right: none;
}

.microcalendar-item-ongoing-reservation {
    left: 0;
    width: 40px;
    border-left: none;
    border-right: none;
}

.microcalendar-item-ending-reservation {
    left: 0;
    width: 10px;
    border-radius: 0 4px 4px 0;
    border-left: none;
}

.microcalendar-item-date-mark {
    position: absolute;
    top: 15px;
    left: 12px;
    width: 3px;
    height: 25px;
    background-color: #1890ff;
}

.difference {
    color: #ff4d4f;
    cursor: pointer;
}

.overriden {
    font-weight: 800;
    cursor: pointer;
}