.cleaners-list-container {
    display: flex;
    flex-direction: column;
}

.cleaners-list-item {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 4px 0px;
    /* outline: 1px solid green; */
}

.cleaners-list-item-name {
    width: 200px;
}

.cleaners-list-item-availability {
    color: #a0a0a0;
}