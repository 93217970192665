.jobs-list-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.jobs-date-list-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.jobs-date-list-header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 4px;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
}

.jobs-date-list-label {
    padding: 8px 0px;
    font-weight: 500;
}

.jobs-date-list-jobs {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.jobs-location-list-container {
    display: flex;
    flex-direction: column;
}

.jobs-location-list-header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 4px;
    align-items: center;
}

.jobs-location-list-header-left {
    display: flex;
    flex: 1 1 0;
    flex-direction: row;
    overflow: hidden;
}

.jobs-location-list-header-right {
    display: flex;
    flex: 2 2 0;
    flex-direction: row;
    overflow: hidden;
}

.jobs-location-list-label {
    width: 300px;
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.jobs-location-list-name {
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.jobs-location-list-storage {
    color: #a0a0a0;
    font-weight: 300;
    overflow: hidden;
    white-space: nowrap;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.jobs-location-list-reservation-situation {
    width: 200px;
}

.jobs-user-group-list-container {
    display: flex;
    flex-direction: column;
}

.jobs-user-group-list-header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 4px;
    align-items: center;
}

.jobs-user-group-list-name {
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.jobs-list-job-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    padding: 4px;
}

.jobs-list-job-container:hover {
    background-color: #fafafa;
}

.jobs-list-job-loading {
    background-color: #e6f7ff !important;
}

.jobs-list-job-container-left {
    display: flex;
    flex: 1 1 0;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    overflow: hidden;
}

.jobs-list-job-container-right {
    display: flex;
    flex: 2 2 0;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    overflow: hidden;
}

.jobs-list-job-title {
    flex-grow: 2;
    flex: 2;
    display: flex;
    flex-direction: row;
    gap: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.jobs-list-job-tags {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.jobs-list-job-date-type-icon {
    color: #a0a0a0;
}

.jobs-list-job-status {
    width: 150px;
    flex-shrink: 0;
}

.jobs-list-job-location {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-wrap: nowrap;
    text-overflow: ellipsis;
}

.jobs-list-job-reservation-situation {
    display: flex;
    width: 150px;
    flex-shrink: 0;
}

.jobs-list-job-assigners {
    flex-grow: 1;
    flex: 1;
    overflow: hidden;
}

.jobs-list-job-watchers {
    flex-grow: 1;
    flex: 1;
    overflow: hidden;
}