.container {
    height: 100%;
    display: flex;
    flex-flow: column;
}

.calendarControlsContainer {
    width: 100%;
    margin-bottom: 16px;
}

.calendarLoadingContainer {
    width: 100%;
    margin-bottom: 16px;
}

.calendarContainer {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.leftPane {
    height: 100%;
}

.rightPane {
    height: 100%;
}

.calendarRowHeaderContainer {
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
}

.calendarRowHeaderContainer::-webkit-scrollbar {
    display: none;
}

.calendarColumnHeaderContainer {
    overflow-x: scroll;
    overflow-y: auto;
    scrollbar-width: none;
    overscroll-behavior-x: contain;
}

.calendarColumnHeaderContainer::-webkit-scrollbar {
    display: none;
}

.calendarColumnHeaderScrollable {
    display: flex;
}

.calendarCellContainer {
    overflow-x: auto;
    overflow-y: auto;
    scrollbar-width: none;
    overscroll-behavior-x: contain;
}

.calendarCellContainer:focus {
    outline: none;
}

.calendarCellContainer::-webkit-scrollbar {
    display: none;
}

.calendarCorner {
    background-color: #FFFFFF;
}

.calendarRowHeaderCell {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    border-top: 1px solid #D8D8D8;
}

.calendarColumnHeaderCell {
    background-color: #FFFFFF;
    border-left: 1px solid #D8D8D8;
}

.calendarCellRow {
    width: 100%;
    display: flex;
}

.calendarCell {
    background-color: #FFFFFF;
    border-top: 1px solid #D8D8D8;
    border-left: 1px solid #D8D8D8;
}