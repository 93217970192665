.toolbelt-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.toolbelt-container hr {
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #f0f0f0;
}

.reservation-info {
    font-size: 16px;
    color: #a0a0a0;
}

.apartment-name {
    font-size: 20px;
    font-weight: 600;
}

.guest-name {
    font-size: 16px;
    color: #a0a0a0;
}

.number-of-guests {
    font-size: 16px;
    color: #a0a0a0;
}

.label {
    font-size: 12px;
    color: #a0a0a0;
}

.value {
    font-size: 20px;
    color: #404040;
}

.reservation-date {
    display: flex;
    flex-direction: column;
}

.reservation-date .value {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.reservation-date .weekday {
    color: #a0a0a0;
}

.jobs-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.jobs-container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.jobs-container-header-title {
    font-size: 16px;
}

.jobs-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.no-jobs {
    text-align: center;
    color: #a0a0a0;
}